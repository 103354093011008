// import readline from 'readline';
const fromHexString = hexString =>
  new Uint8Array(hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));

function concatTypedArrays(a, b) { // a, b TypedArray of same type
    var c = new (a.constructor)(a.length + b.length);
    c.set(a, 0);
    c.set(b, a.length);
    return c;
}

function createUint24(value) {
    var data = new Uint8Array(4);
    var data_view = new DataView(data.buffer);
    data_view.setUint32(0, value, 1);
    return data.subarray(0, 3)
}


// dump(command, command_view, offset, resolve) {
//     if(offset === this.state.flash.flash.size) {
//         console.log("DONE!");
//         this.setState({
//             dumping: false,
//             progress: 100
//         })
//         resolve(this.dumped_data)
//     } else {
//         command_view.setUint32(1, offset, 1)
//         console.log("Dumping " + offset)
//         let dumped = offset;
//         let size = this.state.flash.flash.size;
//         let progress = (dumped / size) * 100;
//         this.setState({
//             dumped: offset,
//             progress: progress
//         })
//         this.serprog.spiOp(0x13, command.subarray(0, 4), 0x10000).then((data) => {
//             this.dumped_data = concatTypedArrays(this.dumped_data, data)
//             if(this.dumping_cancelled) {
//                 this.setState({
//                     dumping: false
//                 })
//                 return
//             }
//             offset += 0x10000
//             this.dump(command, command_view, offset, resolve)
//         })
//     }
// }

// dump_flash() {
//     // this is one byte too long. this is intentional so we can use dataview.
//     var command = fromHexString("0300000000")
//     var command_view = new DataView(command.buffer)
//     var offset = 0;
//     this.setState({
//         dumping: true
//     })
//     this.dumped_data = new Uint8Array()
//     // var promise = new Promise()
//     return new Promise((resolve) => {
//         this.dump(command, command_view, offset, resolve)
//     })
// }
class FlashProgrammer {
    constructor(serprog) {
        this.serprog = serprog
        this.onUpdate = null
        this.progress = 0
        this.cancelled = false
    }


    program_block() {
        if(this.offset === this.size) {
            this.resolve()
            return
        }

        // Offset 1 so we don't overwrite the 03 (Read command)
        this.command_view.setUint32(1, this.offset, 0)
        // Yes I'm a great programmer ;D
        this.command[1] = this.command[2]
        this.command[2] = this.command[3]
        this.command[3] = this.command[4]
        let program_command = concatTypedArrays(this.command.subarray(0, 4), this.data.subarray(this.offset, this.offset+256))
        console.log("Programm comand len: " + program_command.length)
        this.progress = (this.offset/this.size) * 100
        this.onUpdate(this)
        this.serprog.spiOp(0x13, new Uint8Array([0x06]), 0).then((data) => {
            this.serprog.spiOp(0x13, program_command, 0).then((data) => {
                if(this.cancelled) {
                    this.resolve(null)
                    return
                }
                this.offset += this.blocksize
                this.serprog.waitBusy().then(() => {
                    this.program_block()
                })
                
            })
        })
    }

    program(size, data) {
        console.log("Programming " + size + " with data length: " + data.length)
        this.command = fromHexString("0200000000")
        this.command_view = new DataView(this.command.buffer)
        this.data = data
        this.cancelled = false
        this.offset = 0;
        this.blocksize = 256
        this.size = size
        
        var self = this
        var promise = new Promise((resolve) => {
            this.serprog.spiOp(0x13, new Uint8Array([0x06]), 0).then((data) => {
                // var promise = new Promise()
                
                self.resolve = resolve

                self.program_block()
            })
        })
        
        return promise
    }

    cancel() {
        this.cancelled = true
    }
}

class FlashEraser {
    constructor(serprog) {
        this.serprog = serprog
        this.onUpdate = null
        this.progress = 0
        this.cancelled = false
    }


    erase_block() {
        if(this.offset === this.size) {
            this.resolve()
            return
        }

        // Offset 1 so we don't overwrite the 03 (Read command)
        this.command_view.setUint32(1, this.offset, 0)
        // Yes I'm a great programmer ;D
        this.command[1] = this.command[2]
        this.command[2] = this.command[3]
        this.command[3] = this.command[4]
        this.progress = (this.offset/this.size) * 100
        this.onUpdate(this)
        this.serprog.spiOp(0x13, new Uint8Array([0x06]), 0).then((data) => {
            this.serprog.spiOp(0x13, this.command.subarray(0, 4), 0).then((data) => {
                if(this.cancelled) {
                    this.resolve(null)
                    return
                }
                this.offset += this.blocksize
                this.serprog.waitBusy().then(() => {
                    this.erase_block()
                })
                
            })
        })
    }

    erase(size) {
        this.command = fromHexString("2000000000")
        this.command_view = new DataView(this.command.buffer)

        this.cancelled = false
        this.offset = 0;
        this.blocksize = 4*1024
        this.size = size
        
        var self = this
        var promise = new Promise((resolve) => {
            this.serprog.spiOp(0x13, new Uint8Array([0x06]), 0).then((data) => {
                // var promise = new Promise()
                
                self.resolve = resolve

                self.erase_block()
            })
        })
        
        return promise
    }

    cancel() {
        this.cancelled = true
    }
}

class FlashDumper {
    constructor(serprog) {
        this.serprog = serprog
        this.onUpdate = null
        this.progress = 0
        this.cancelled = false
    }


    dump_block() {
        if(this.offset === this.size) {
            this.resolve(this.dumped_data)
            return
        }

        // Offset 1 so we don't overwrite the 03 (Read command)
        this.command_view.setUint32(1, this.offset, 0)
        this.command[1] = this.command[2]
        this.command[2] = this.command[3]
        this.command[3] = this.command[4]
        this.progress = (this.offset/this.size) * 100
        this.onUpdate(this)
        this.serprog.spiOp(0x13, this.command.subarray(0, 4), this.blocksize).then((data) => {
            this.dumped_data = concatTypedArrays(this.dumped_data, data.subarray(1))
            if(this.cancelled) {
                this.resolve(null)
                return
            }
            this.offset += this.blocksize
            this.dump_block()
        })
    }

    dump(blocksize, size) {
        this.command = fromHexString("0300000000")
        this.command_view = new DataView(this.command.buffer)
        this.dumped_data = new Uint8Array()

        this.cancelled = false
        this.offset = 0;
        this.blocksize = blocksize
        this.size = size
        
        // var promise = new Promise()
        return new Promise((resolve) => {
            this.resolve = resolve
            this.dump_block()
        })
    }

    cancel() {
        this.cancelled = true
    }
}

class Serprog {
    constructor(serial) {
        this.serial = serial;
    }

    // 130100000300009f
    spiOp(command, write, read_length) {
        let cb = new Uint8Array([command])
        let wb = createUint24(write.length)
        let rb = createUint24(read_length)

        let b = concatTypedArrays(concatTypedArrays(concatTypedArrays(cb, wb), rb), write)
        console.log("Data: " + Buffer.from(b).toString('hex'))

        this.serial.write(b)
        return this.serial.read(read_length + 1)
    }

    isBusy() {
        return new Promise((resolve) => {
            this.readStatus(0).then((status) => {
                if(status[0] & 0x1) {
                    console.log("Is busy")
                    resolve(true)
                } else {
                    console.log("Is not busy")
                    resolve(false)
                }
            })
        })
    }

    waitBusy() {
        var self = this
        var cb = function(resolve) {
            self.isBusy().then((busy) => {
                if(busy) {
                    cb(resolve)
                } else {
                    resolve()
                }
            })
        }
        return new Promise((resolve) => {
            cb(resolve)
        })
        
        // this.isBusy().then({busy} => {
        //     if(busy) {
        //         waitBusy()
        //     }
        // })
    }
    

    readStatus(register_number) {
        let status_op = 0x05;
        if(register_number == 1) {
            status_op = 0x35;
        }

        return new Promise((resolve, reject) => {
            this.spiOp(0x13, new Uint8Array([status_op]), 1).then((data) => {
                console.log("Status: " + data)
                if(data[0] != 0x06) {
                    reject("Did not get ACK from adapter.")
                }
                resolve(data.subarray(1))
            })
        })
    }

    readId() {
        return new Promise((resolve) => {
            this.spiOp(0x13, new Uint8Array([0x9f]), 3).then((data) => {
                console.log("Got chipID: " + data)
                let dv = new DataView(data.buffer)
                let id1 = dv.getUint8(1)
                let id2 = dv.getUint16(2, 0)
                console.log("Id: " + id1 + " - " + id2)
                // 13 - 01 00 00 02 00 00 05 
                resolve({
                    "id1": id1,
                    "id2": id2
                })
            })
        })
    }

    readSFDP(address, length) {
        return new Promise((resolve, reject) => {
            let data = new Uint8Array(5)
            let data_view = new DataView(data.buffer)
            data[0] = 0x5a // Read SFDP command
            data_view.setUint32(1, address, 0)
            this.spiOp(0x13, data, length).then((data_in) => {
                if(data_in[0] !== 0x06) {
                    console.log("Failed to read SFDP. Response not 0x06.")
                    reject("Failed to read SFDP.")
                    return
                }

                console.log("Got SFDP: " + data_in)
                let data = data_in.subarray(1)
                let data_view = new DataView(data.buffer)
                
                
                // Parse SFDP
                // https://www.taterli.com/wp-content/uploads/2017/07/JESD216.pdf

                // Check hedaer bytes ("SFDP")
                let header_string = new TextDecoder().decode(data.subarray(0, 4))
                console.log("Header string: " + header_string)
                if(header_string !== "SFDP") {
                    console.log("Failed to read SFDP, header bytes wrong.")
                    reject("Failed to read SFDP: Header does not match SFDP.")
                    return
                }

                let sfdp = {}
                sfdp["minor_revision"] = data_view.getUint8(4)
                sfdp["major_revision"] = data_view.getUint8(5)
                sfdp["nph"] = data_view.getUint8(6)
                sfdp["jedec_id"] = data_view.getUint8(8)
                sfdp["parameter_minor_revision"] = data_view.getUint8(9)
                sfdp["parameter_major_revision"] = data_view.getUint8(10)
                sfdp["parameter_length"] = data_view.getUint8(11) * 4 // converted to bytes
                sfdp["ptp"] = data_view.getUint32(12, 1) >> 8

                let fpt = new Uint8Array(data.subarray(sfdp["ptp"])) // fpt = Flash Parameter Table
                console.log(fpt)
                let fpt_view = new DataView(fpt.buffer)

                // block/sector erase sizes
                // 01 = 4k erases OK
                // 11 = no 4k erases
                let bs_erase_sizes = fpt[0] & 0b11
                sfdp["bs_erase_sizes"] = bs_erase_sizes
                sfdp["write_granularity"] = (fpt[0] >> 2) & 0b1
                sfdp["write_enable_instruction_required"] = (fpt[0] >> 3) & 0b1
                sfdp["write_enable_opcode"] = (fpt[0] >> 4) & 0b1
                sfdp["4k_erase_opcode"] = fpt[1]
                sfdp["address_bytes_raw"] = (fpt[2] >> 1) & 0b11

                switch(sfdp["address_bytes_raw"]) {
                    case 0b00:
                    case 0b01:
                        sfdp["address_bytes"] = 3
                        break;
                    case 0b10:
                        sfdp["address_bytes"] = 4
                        break;
                    default:
                        reject("Invalid address bytes information.")
                        break;
                }

                // TODO: Fails to parse the upper bit for larger flashes
                let flash_memory_density = fpt_view.getUint32(4, 1) + 1
                sfdp["density"] = flash_memory_density


                

                // let dv = new DataView(data.buffer)
                // let id1 = dv.getUint8(1)
                // let id2 = dv.getUint16(2, 0)
                // console.log("Id: " + id1 + " - " + id2)
                // 13 - 01 00 00 02 00 00 05 
                resolve(sfdp)
            })
        })
    }
}

class Serial {
    constructor() {
        this.leftover = "";
        this.data = new Uint8Array();
        this.read_in_progress = false;
    }

    static requestPort() {
        return navigator.serial.requestPort().then(
            device => {
                return device;
            }
        );
        
    }
    getDevice() {
        let device = null;
        this.ready = false;
        return new Promise((resolve, reject) => {
            Serial.requestPort().then(dev => {
                console.log("Opening device...");
                device = dev;
                this.device = device;
                return this.device.open({baudRate: 115200});
            }).then(() => {
                console.log("Open!");
                this.ready = true;
                this.reader = this.device.readable.getReader();
                this.writer = this.device.writable.getWriter();
                resolve();
            });
        });
    }


    readLoop(resolve, length) {
        // Check whether we have enough data
        if(this.data.length >= length) {
            let return_data = this.data.subarray(0, length)
            this.data = this.data.subarray(length)
            console.log("Returning data: " + return_data.length + " - " + Buffer.from(return_data).toString('hex'))
            this.read_in_progress = false
            resolve(return_data)
        } else { // If we don't have enough data we just read again
            this.reader.read().then(({done, value}) => {
                // Yes this is hacky, sorry, I don't really JS :D
                this.data = concatTypedArrays(this.data, value)
                this.readLoop(resolve, length)
            })
        }

    }

    write(data) {
        this.writer.write(data)
    }

    read(length) {
        if(this.read_in_progress) {
            console.log("READ IS ALREADY IN PROGRESS!");
            // TODO FAIL
        }
        this.read_in_progress = true;
        return new Promise((resolve) => {
            this.readLoop(resolve, length)
        })
    }
}

export { Serial , Serprog, FlashDumper, FlashEraser, FlashProgrammer };
