import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal, ProgressBar } from 'react-bootstrap';


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle'
import './index.css';

import { Serial, Serprog, FlashDumper, FlashEraser, FlashProgrammer } from './serial.js';
import { Flashchips } from './flashes.js';

global.jQuery = require('jquery');
require('bootstrap');
function concatTypedArrays(a, b) { // a, b TypedArray of same type
    var c = new (a.constructor)(a.length + b.length);
    c.set(a, 0);
    c.set(b, a.length);
    return c;
}
const fromHexString = hexString =>
  new Uint8Array(hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));

const downloadURL = (data, fileName) => {
    const a = document.createElement('a')
    a.href = data
    a.download = fileName
    document.body.appendChild(a)
    a.style.display = 'none'
    a.click()
    a.remove()
}

const downloadBlob = (data, fileName, mimeType) => {
    const blob = new Blob([data], {
        type: mimeType
    })

    const url = window.URL.createObjectURL(blob)

    downloadURL(url, fileName)
    setTimeout(() => window.URL.revokeObjectURL(url), 1000)
}
function buildFileSelector(){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    return fileSelector;
}

class FlashingModal extends React.Component {
    constructor(props) {
        super(props)
    }

    handleCancel() {
        if(this.props.onCancel) {
            this.props.onCancel()
        }
        
    }

    render() {
        let text = "Dumping flash..."
        if(this.props.cancelling) {
            text = "Cancelling..."
        }

        return <>
        <Modal show={this.props.show} onHide={() => this.handleCancel()} animation={true}>
            <Modal.Header closeButton>
                <Modal.Title>Dumping</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
                <ProgressBar now={this.props.progress} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.handleCancel()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    }
}

class ErasingModal extends FlashingModal {
    render() {
        let text = "Erasing flash..."
        if(this.props.cancelling) {
            text = "Cancelling..."
        }

        return <>
        <Modal show={this.props.show} onHide={() => this.handleCancel()} animation={true}>
            <Modal.Header closeButton>
                <Modal.Title>Erasing</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
                <ProgressBar variant="danger" now={this.props.progress} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.handleCancel()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    }
}

class ProgrammingModal extends FlashingModal {
    render() {
        let text = "Programming flash..."
        if(this.props.cancelling) {
            text = "Cancelling..."
        }

        return <>
        <Modal show={this.props.show} onHide={() => this.handleCancel()} animation={true}>
            <Modal.Header closeButton>
                <Modal.Title>Programming</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
                <ProgressBar variant="success" now={this.props.progress} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.handleCancel()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    }
}

class FlashDumperUI extends React.Component {


    
    ArcStateConnect = "connect";
    ArcStateConnecting = "connecting";
    ArcStateIdle = "idle";
    ArcStateGame = "game";
    ArcStateOver = "over";

    constructor(props) {
        super(props);
        this.state = {
            programmerName: "",
            programmerVersion: 0,
            flash: null,
            id1: 0,
            id2: 0,
            sfdp: null,
            dumping: false,
            erasing: false,
            programming: false,
            dumped: 0,
            dumping_cancelled: false,
            progress: 10,
            player1: 0,
            player2: 0,
            time: 0,
            arcade: this.ArcStateConnect,
        }
    }

    handleConnectClick() {
        this.serial = new Serial();
        this.setState({
            arcade: this.ArcStateConnecting
        });
        this.serial.getDevice().then(() => {
            console.log("Serial connected, updating status.");
            this.setState({
                arcade: this.ArcStateIdle
            });

            this.serial.write(new Uint8Array([0x03]))
            this.serial.read(17).then((data) => {
                console.log("Got programmer name: " + data)
                this.setState({
                    programmerName: new TextDecoder().decode(data.subarray(1))
                })
                this.serial.write(new Uint8Array([0x01]))
                return this.serial.read(3)
            }).then((data) => {
                console.log("Got version: " + data)
                let version_bytes = data.subarray(1)
                let data_view = new DataView(version_bytes.buffer)
                let version = data_view.getUint16(1, 1)
                this.setState({
                    programmerVersion: version
                })
                this.serprog = new Serprog(this.serial)

                // return this.serprog.spiOp(0x13, new Uint8Array([0x9f]), 3);
            })/*.then((data) => {
                console.log("Got chipID: " + data)
                
            })*/
            // this.readSerial();
        }).catch(c => {
            console.log("Catch");
            this.setState({
                arcade: this.ArcStateConnect
            });
        });
    }

    handleIdentifyClick() {
        this.setState({
            sfdp: null,
            flash: null,
            id1: 0, 
            id2: 0
        })
        var self = this
        console.log(this.serprog)
        this.serprog.readSFDP(0, 256).then((data) => {
            self.setState({
                sfdp: data
            })
            self.handleReadIdClick()
        })
    }

    handleReadSFDPClick() {
        this.serprog.readSFDP(0, 256).then((data) => {
            console.log("GOT SFDP: " + data);
            console.log(data)
            this.setState({
                sfdp: data
            })
            console.log("Set state")
        })
    }
    handleReadIdClick() {
        this.serprog.readId().then((data) => {
            let flash = Flashchips.findChip(data.id1, data.id2) 
            this.setState({
                id1: data.id1,
                id2: data.id2,
                flash: flash
            })
            // console.log("Data: " + data.id1)
            
        })
        // this.serprog.spiOp(0x13, new Uint8Array([0x9f]), 3).then((data) => {
        //     console.log("Got chipID: " + data)
        //     // 13 - 01 00 00 02 00 00 05 
        //     return this.serprog.spiOp(0x13, fromHexString("05"), 0x2);
        // }).then((data) => {
        //     console.log("post 05")
        //     return this.serprog.spiOp(0x13, fromHexString("03000000"), 0x10000);
        // }).then((data) => {
        //     console.log("Data len: " + data.length);
        //     console.log(data)
        // })
    }

    handleCancelDumpingClick() {
        this.dumping_cancelled = true
        this.dumper.cancel()
    }

    handleCancelErasingClick() {
        this.dumping_cancelled = true
        this.eraser.cancel()
    }

    handleCancelProgrammingClick() {
        this.dumping_cancelled = true
        this.programmer.cancel()
    }

    dump(size) {
        this.setState({
            dumping: true,
            progress: 0
        })
        this.dumper = new FlashDumper(this.serprog)
        var self = this

        this.dumper.onUpdate = (dumper) => {
            console.log("Update!")
            console.log(dumper.progress)
            this.setState({
                progress: dumper.progress
            })
        }
        this.dumper.dump(1024, size).then((data) => {
            self.setState({
                dumping: false,
                dumping_cancelled: false
            })
            if(!data) {
                return;
            }

            downloadBlob(data, "flashdump.bin", "application/octet-stream")
        })
    }

    loadFile() {
        var self = this
        return new Promise((resolve) => {
            let fs =buildFileSelector();
            fs.onchange = function(e) {
                console.log("File selected");
                var reader = new FileReader()
                reader.onload = function(e) {
                    console.log("On load")
                    console.log(e.target.result)
                    resolve(e.target.result)
                }
                reader.readAsArrayBuffer(fs.files[0])
            }
            fs.click();
        })
    }

    program(size, data) {
        var self = this
        
        self.programmer = new FlashProgrammer(self.serprog)
        self.setState({
            programming: true,
            dumping_cancelled: false,
            progress: 0
        })
        self.programmer.onUpdate = function(u) {
            console.log("programming update")
            console.log(u.progress)
            self.setState({
                progress: u.progress
            })
        }
        return new Promise((resolve) => {
            self.programmer.program(size, new Uint8Array(data)).then(() => {
                self.setState({
                    programming: false,
                    dumping_cancelled: false,
                    progress: 0
                })
                resolve()
            })
        })
    }

    erase(size) {
        this.setState({
            erasing: true,
            dumping_cancelled: false,
            progress: 0
        })
        this.eraser = new FlashEraser(this.serprog)
        var self = this
        this.eraser.onUpdate = (eraser) => {
            console.log("Update!")
            console.log(eraser.progress)
            this.setState({
                progress: eraser.progress
            })
        }
        return new Promise((resolve) => {
            this.eraser.erase(size).then((data) => {
                self.setState({
                    erasing: false,
                    dumping_cancelled: false,
                    progress: 0
                })
                resolve()
            })
        })
    }

    handleDumpSFDPClick() {
        this.dump(this.state.sfdp.density/8)
    }

    handleDumpIDClick() {
        this.dump(this.state.flash.flash.size/8)
    }
 
    _handleEraseAndProgram(size) {
        var self = this
        let idata = null
        this.loadFile().then((data) => {
            idata = data
            return self.erase(size)
        }).then(() => {
            console.log("Test")
            return self.program(size, idata)
        })
    }

    handleEraseAndProgramSFDPClick() {
        this._handleEraseAndProgram(this.state.sfdp.density/8)
    }

    handleEraseAndProgramIDClick() {
        this._handleEraseAndProgram(this.state.flash.flash.size/8)
    }


    handleEraseSFDPClick() {
        this.erase(this.state.sfdp.density/8)
    }

    handleEraseIDClick() {
        this.erase(this.state.flash.flash.size/8)
    }

    handleProgramSFDPClick() {
        var self = this
        this.loadFile().then((data) => {
            self.program(self.state.sfdp.density/8, data)
        })
    }

    handleProgramIDClick() {
        var self = this
        this.loadFile().then((data) => {
            self.program(this.state.flash.flash.size/8, data)
        })
    }

    renderFlash() {
        var identified_chip = null
        var dump_button = null
        if(this.state.flash) {
            identified_chip = <>
                <tr><td>Manufacturer:</td><td>{this.state.flash.manufacturer.manufacturer}</td></tr>
                <tr><td>Model:</td><td>{this.state.flash.flash.name}</td></tr>
                <tr><td>Size:</td><td>{this.state.flash.flash.size} ({this.state.flash.flash.size/1024} Kilobit)</td></tr>
            </>
            dump_button = <>
                <div class="btn-group" role="group" aria-label="Basic mixed styles example">
                    <a href="/#" onClick={(e) => this.handleDumpIDClick()} className="btn btn-primary">Dump</a>
                    <a href="/#" onClick={(e) => this.handleEraseIDClick()} className="btn btn-primary">Erase</a>
                    <a href="/#" onClick={(e) => this.handleProgramIDClick()} className="btn btn-primary">Program</a>
                    <a href="/#" onClick={(e) => this.handleEraseAndProgramIDClick()} className="btn btn-primary">Erase &amp; Program</a>
                </div>
            </>
        } else {
            identified_chip = <>
                <tr><td colSpan="2">Don't have this chip in the database.</td></tr>
            </>
        }
        if(this.state.id1 !== 0) {
            return <div className="col-sm-6">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Chip ID (0x9F)</h5>
                        <div className="card-text">
                            <table className="table table-borderless table-sm">
                                <tbody>
                                    <tr><th colSpan="2">Raw ID</th></tr>
                                    <tr><td>ID1:</td><td>0x{this.state.id1.toString(16).toUpperCase()}</td></tr>
                                    <tr><td>ID2:</td><td>0x{this.state.id2.toString(16).toUpperCase()}</td></tr>
                                    <tr><th colSpan="2">Identified chip</th></tr>
                                    {identified_chip}
                                </tbody>
                            </table>
                            
                        </div>
                        {dump_button}
                    </div>
                </div>
            </div>
        } else {
            return (null)
        }
    }

    renderSFDP() {
        if(this.state.sfdp) {
            return <div className="col-sm-6">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">SFDP Information</h5>
                        <div className="card-text">
                            <table className="table table-borderless table-sm">
                                <tbody>
                                    <tr><td>Flash size:</td><td>{this.state.sfdp.density} ({this.state.sfdp.density / 1024} Kilobit)</td></tr>
                                    <tr><td>Write enable required:</td><td>{this.state.sfdp.write_enable_instruction_required}</td></tr>
                                    <tr><td>Address bytes</td><td>{this.state.sfdp.address_bytes}</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="btn-group" role="group" aria-label="Basic mixed styles example">
                            <a href="/#" onClick={(e) => this.handleDumpSFDPClick()} className="btn btn-primary">Dump</a>
                            <a href="/#" onClick={(e) => this.handleEraseSFDPClick()} className="btn btn-primary">Erase</a>
                            <a href="/#" onClick={(e) => this.handleProgramSFDPClick()} className="btn btn-primary">Program</a>
                            <a href="/#" onClick={(e) => this.handleEraseAndProgramSFDPClick()} className="btn btn-primary">Erase &amp; Program</a>
                        </div>
                    </div>
                </div>
            </div>
        } else {
            return (null)
        }
    }

    render() {
        if (navigator.serial) {
            if (this.state.arcade === this.ArcStateConnect) {
                return (
                    <div className="connect text-center">
                        <button onClick={(e) => this.handleConnectClick()} className="btn btn-lg btn-secondary">Connect</button>
                        <br />
                        <small><a href="https://github.com/stacksmashing/pdnd-serprog/">Requires a serprog compatible flash adapter. Tested with Pico Debug'n'Dump.</a></small><br/>
                        <small>Version: alpha</small>
                    </div>
                )
            } else if (this.state.arcade === this.ArcStateConnecting) {
                return (
                    <div className="connect text-center">
                        <h1>Connecting...</h1>
                    </div>
                )

            } else if (this.state.arcade === this.ArcStateIdle) {
                return (
                    <div className="">
                        <div className="container text-center">
                            <h3>Connected to your flash adapter!</h3>
                            <p>Programmer name: {this.state.programmerName} - Version: {this.state.programmerVersion}</p>
                            <p>
                                <button onClick={(e) => this.handleIdentifyClick()} className="btn btn-secondary">Identify flash</button>
                                {/* <button onClick={(e) => this.handleReadIdClick()} className="btn btn-secondary">Read ID</button>
                                <button onClick={(e) => this.handleReadSFDPClick()} className="btn btn-secondary">Read SFDP</button> */}
                            </p>
                            <FlashingModal show={this.state.dumping} progress={this.state.progress} onCancel={() => this.handleCancelDumpingClick()} cancelling={this.state.dumping_cancelled}/>
                            <ErasingModal show={this.state.erasing} progress={this.state.progress} onCancel={() => this.handleCancelErasingClick()} cancelling={this.state.dumping_cancelled}/>
                            <ProgrammingModal show={this.state.programming} progress={this.state.progress} onCancel={() => this.handleCancelProgrammingClick()} cancelling={this.state.dumping_cancelled}/>
                            
                        </div>
                        <div className="container">
                            <div className="row">
                                {this.renderSFDP()}
                                {this.renderFlash()}
                            </div>
                        </div>
                    </div>)
            }
        } else {
            return (
                <h2>Sorry, your browser does not support Web Serial!</h2>
            )
        }
    }
}

// ========================================

ReactDOM.render(
    <FlashDumperUI />,
    document.getElementById('root')
);
