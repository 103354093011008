// pagesize currently not validated
const flashchips = [
    {
        manufacturer: "ISSI",
        manufacturerId: 0x9D,
        flashes: [
            {
                name: "IS25WP128",
                id: 0x7018,
                size: 134217728,
                page_size: 256
            },
            {
                name: "IS25WP64",
                id: 0x7017,
                size: 67108864,
                page_size: 256
            },
            {
                name: "IS25WP32",
                id: 0x7016,
                size: 33554432,
                page_size: 256
            }
        ]
    },
    {
        manufacturer: "Macronix",
        manufacturerId: 0xC2,
        flashes: [
            {
                name: "MX25U12835E",
                id: 0x2538,
                size: 134217728,
                page_size: 256
            },
            {
                name: "MX25U8035E",
                id: 0x2534,
                size: 8388608,
                page_size: 256
            }
        ]
    },
    {
        manufacturer: "Winbond Nex",
        manufacturerId: 0xef,
        flashes: [
            {
                name: "W25Q80.V",
                id: 0x4014,
                size: 8388608, // bytes
                page_size: 256
            },
            {
                name: "W25Q64JVSIM",
                id: 0x7017,
                size: 67108864,
                page_size: 256
            },
            {
                name: "W25Q128JVSM",
                id: 0x7018,
                size: 134217728,
                page_size: 256
            },
            {
                name: "W25Q16",
                id: 0x4015,
                size: 16777216,
                page_size: 256
            }
        ]
    }
]

class Flashchips {
    static findChip(id1, id2) {
        for(const manufacturer of flashchips) {
            if(manufacturer.manufacturerId === id1) {
                console.log("Manufacturer: " + manufacturer.manufacturer)
                for(const flash of manufacturer.flashes) {
                    if(flash.id === id2) {
                        return {
                            "manufacturer": manufacturer,
                            "flash": flash
                        }
                    }
                }
            }
        }
    }
}


export { Flashchips };